import {
  createApp,
  createAppInitializer,
} from '@basaldev/blocks-frontend-framework';
import { api, session } from '@basaldev/blocks-frontend-sdk';
import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';

import '@basaldev/blocks-frontend-framework/dist/style.css';
import './core-styles.css';
import { SentryLogger } from './sentryLogger';
import { GeekleAdminAppTemplate } from './template';

const sessionService = new session.CookieSessionService();

const authApi = new api.AuthDefaultAdapterApi(
  '/api/admin-auth',
  sessionService
);

const organizationApi = new api.OrganizationDefaultAdapterApi(
  '/api/organization',
  sessionService
);

const userApi = new api.UserDefaultAdapterApi<{
  is_signup_wizard_complete: boolean;
}>('/api/admin-user', sessionService);

const demandSiteOrganizationUrl =
  'https://demand.geekle.jp/organization/:organizationId';

const template = new GeekleAdminAppTemplate(
  {
    appName: 'Geekle Frontend Admin',
    logger: new SentryLogger({
      appName: 'Geekle Frontend Admin',
      env: 'production',
    }),
    pageTitleConfiguration: {
      appName: 'Geekle',
    },
  },
  {
    authApi,
    demandSiteOrganizationUrl,
    organizationApi,
    sessionService,
    userApi,
  }
);

template.opts.appInitialization.unshift(async () => {
  Sentry.init({
    dsn: 'https://db9e348d2247fb1980319b7b4746ea3c@o432782.ingest.us.sentry.io/4507224942903296',
    integrations: [Sentry.replayIntegration()],
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    tracesSampleRate: 1.0,
  });
  return true;
});

const appInitializer = createAppInitializer({ template });
const App = createApp({ appInitializer, template });

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
